$roboto-font-path: '../node_modules/roboto-fontface/fonts';
$material-icons-font-path: '../node_modules/material-icons/iconfont/';

@import "roboto-fontface/css/roboto/sass/roboto-fontface-regular.scss";
@import "roboto-fontface/css/roboto/sass/roboto-fontface-regular-italic.scss";
@import "roboto-fontface/css/roboto/sass/roboto-fontface-bold.scss";
@import "roboto-fontface/css/roboto/sass/roboto-fontface-bold-italic.scss";


@import 'material-icons/iconfont/material-icons.scss';

body,
button,
input,
ul,
ol {
    font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3 {
    font-weight: 400;
}

h4 {
    font-weight: 600;
    margin-block-end: 0.2em;
}

p {
    margin-block-start: 0.2em;
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0 1em;
    margin-bottom: 2em;
}

.section {
    max-width: 40em;
    width: 100%;
}

$btn-background : #97cf65;
$btn-border: #366f00;
$btn-background-hover: lighten($btn-background, 10);
$btn-background-active: #97cf65;


button {
    display: inline-block;
    padding: 0.5em 1em;
    font-weight: 500;
    border-radius: 3px;
    background-color: #97cf65;
    transition: .2s cubic-bezier(.3, 0, .5, 1);
    transition-property: background-color, color;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border: 0.1em solid transparent;
    outline: none;

    &:hover {
        background-color: $btn-background-hover;
        transition-duration: .1s;
    }

    &:focus-visible {
        border-color: $btn-border;
    }

    &:active {
        background-color: $btn-background-active;
        transition: none;
    }

    &:disabled {
        color: #999;
        background-color: #ddd;
    }
}

a {
    color: #1e88e5;

    :visited {
        color: #1e88e5;
    }

    :active {
        color: #1e88e5;
    }
}

.link-section {
    display: flex;
    justify-content: flex-end;
    padding: 0.2em;

    &>a:not(:last-of-type) {
        padding-right: 0.5em;
    }
}


@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}



.status-msg {
    display: flex;
    align-items: center;

    &>.material-icons {
        margin-right: 0.2em;
    }

    &.saving {
        &>.material-icons {
            animation: spin 1s ease-in-out infinite;
        }
    }

    &.my-bet,
    &.correct-bet {
        &>.material-icons {
            color: #97cf65;
        }
    }

    &.error {
        &>.material-icons {
            color: #ba000d;
        }
    }

    &.warning {
        &>.material-icons {
            color: #fdd835;
        }
    }
}

table {
    width: 100%;

    td {
        padding: 0.2em;
    }

    tbody td {
        padding: 0.3em;
    }

    thead td {
        font-weight: 500;
    }

    thead td {
        border-bottom: 0.1em solid black;
    }

    border-collapse: collapse;

    tbody tr:nth-child(2n) {
        background-color: lighten(#7cb342, 45);
    }

    tbody tr:hover {
        background-color: lighten(#7cb342, 35);
    }
}