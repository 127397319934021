.odds-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    justify-items: center;
    grid-template-areas:
        "team1 . team2"
        "time time time"
        "choice-team1 choice-draw choice-team2";
    margin-bottom: 0.6em;

    &>h3 {

        &>.flag-icon {
            margin: 0 0.2em;
            -webkit-box-shadow: 1px 1px 1px 0px rgba(41, 41, 41, 1);
            -moz-box-shadow: 1px 1px 1px 0px rgba(41, 41, 41, 1);
            box-shadow: 1px 1px 1px 0px rgba(41, 41, 41, 1);
        }

        margin: 0;
        padding: 0;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}

.time {
    grid-area: time;
    margin-bottom: 0.2em;
}

.team1 {
    grid-area: team1;
}

.team2 {
    grid-area: team2;
}

.choice-team1 {
    grid-area: choice-team1;
}

.choice-draw {
    grid-area: choice-draw;
}

.choice-team2 {
    grid-area: choice-team2;
}

upcoming-game-display {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
    display: block;
    // padding: 0.8em;
    margin-bottom: 0.5em;
    position: relative;
}

.upcoming-game-display {
    &__content {
        padding: 0.8em;
    }
}