bets-display {
    display: block;
    border-top: 1px solid #e0e0e0;
}

.bets-display {
    &__tab-header {
        display: flex;
        button {
            box-shadow: none;
            border-radius: 0;
            flex: 1;
            padding: 0.5em;
            border: none;
            background-color: #f5f5f5;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: #e0e0e0;
            }

            &.expand-button {
                &--active {
                    background-color: lighten(#97cf65, 10);
                }
            }
            
        
        }
        button:not(:last-of-type) {
            border-right: 1px solid #e0e0e0;
        }
    }
    &__content {
       padding: 0.5em;
    
    }
}

.bets-table {
    tr>td:not(:first-of-type) {
        text-align: center;
        width: 3em;
    }

    .material-icons {
        margin-top: 4px;
        font-size: 16px;
    }
}